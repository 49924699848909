import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react'
import MacWindowModel from 'models/MacWindowModel';
import IFrameWindow from 'components/IFrameWindow/IFrameWindow';
import APP_CONSTANT from 'constants/AppConstant';
import { useDispatch } from 'react-redux';
import { bringWindowToFront, minimizeWindow, removeWindow } from 'slices/windowListSlice';
import { contentMap } from 'data/macWindowData';
import { saveWindowPosition } from 'slices/appListSlice';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

interface MacWindowProps {
    window: MacWindowModel
}

function MacWindow(props: MacWindowProps): React.ReactElement {
    const { windowId, windowContentId, windowAppId, isWindowSuspended, windowIFrameContent, windowRenderSize, windowRenderCoord } = props.window
    const dispatch = useDispatch();
    const dockIconLocationState = useSelector((state: RootState) => state.dockIconList);
    const [isDragging, setIsDragging] = useState<boolean>(false)
    const [windowPosition, setWindowPosition] = useState<{ x: number, y: number }>({ x: windowRenderCoord.x, y: windowRenderCoord.y + APP_CONSTANT.MENUBAR_HEIGHT })
    const [windowSize, setWindowSize] = useState<{ x: number, y: number }>({ x: 0, y: 0 })
    const [clickPositionOffset, setClickPositionOffset] = useState<{ x: number, y: number }>({ x: 0, y: 0 })
    const [hiddenVariant, setHiddenVariant] = useState({})
    const windowTopRef = useRef<HTMLDivElement>(null)
    const windowRef = useRef<HTMLDivElement>(null)

    // --------------------- mouse movement functionality-----------------------
    const calculateNewPosition = useCallback((clientX: number, clientY: number) => {
        if (!windowRef.current) return { x: 0, y: 0 };
        const windowRect = windowRef.current.getBoundingClientRect();
        return {
            x: clamp(clientX - clickPositionOffset.x, 0, window.innerWidth - windowRect.width),
            y: clamp(clientY - clickPositionOffset.y, APP_CONSTANT.MENUBAR_HEIGHT, window.innerHeight - windowRect.height)
        };
    }, [clickPositionOffset]);

    const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!windowTopRef.current) return;
        const windowTopRect = windowTopRef.current.getBoundingClientRect()
        setIsDragging(true);
        setClickPositionOffset({
            x: event.clientX - windowTopRect.left,
            y: event.clientY - windowTopRect.top,
        })
    };

    const onMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    const onMouseMove = useCallback((event: MouseEvent) => {
        if (!isDragging) return;
        setWindowPosition(calculateNewPosition(event.clientX, event.clientY))
    }, [isDragging, calculateNewPosition]);

    useEffect(() => {
        document.addEventListener("mousemove", onMouseMove)
        document.addEventListener("mouseup", onMouseUp)

        return () => {
            document.removeEventListener("mousemove", onMouseMove)
            document.removeEventListener("mouseup", onMouseUp)
        };
    }, [onMouseMove, onMouseUp])
    //---------------------------------------------------------------

    // close button functionality
    function handleCloseButtonClicked() {
        dispatch(removeWindow(windowId))
    }

    function handleMinimizeButtonClicked() {
        if (!windowRef.current) return;
        const windowRect = windowRef.current.getBoundingClientRect();
        const windowToSuspend = {
            ...props.window,
            windowRenderCoord: { x: windowRect.left, y: windowRect.top },
            windowRenderSize: { width: windowRect.width, height: windowRect.height }
        };
        dispatch(saveWindowPosition(windowToSuspend));
        dispatch(minimizeWindow(windowId));
    }

    function handleClickOnWindowToFocus() {
        dispatch(bringWindowToFront(windowId))
    }

    const updateWindowSize = useCallback(() => {
        if (!windowRef.current) return

        const currentWindowRect = windowRef.current.getBoundingClientRect()
        const { innerHeight, innerWidth } = window

        let newHeight, newWidth

        //mobile devices: fit all side of macwindow to viewport
        if (innerWidth <= 768) {
            newHeight = innerHeight - currentWindowRect.top
            newWidth = innerWidth - currentWindowRect.left
        }
        else {
            newHeight = windowRenderSize.height > innerHeight - currentWindowRect.top ? innerHeight - currentWindowRect.top : windowRenderSize.height
            newWidth = windowRenderSize.width > innerWidth - currentWindowRect.left ? innerWidth - currentWindowRect.left : windowRenderSize.width
        }

        setWindowSize({ x: newWidth, y: newHeight })
    }, [windowRenderSize])

    //size window
    useLayoutEffect(() => {
        window.addEventListener('resize', updateWindowSize);

        updateWindowSize();

        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, [windowRenderSize, updateWindowSize]);


    useEffect(() => {
        const dockIconLocationForWindow = dockIconLocationState.find(dockIcon => dockIcon.appId === windowAppId);
        if (!dockIconLocationForWindow) return;
        setHiddenVariant({
            opacity: 0.5,
            scale: 0,
            x: dockIconLocationForWindow.x - (windowPosition.x + windowSize.x / 2),
            y: dockIconLocationForWindow.y - (windowPosition.y + windowSize.y / 2),
            transition: { duration: 0.5 },
            transitionEnd: { display: 'none' }
        });
    }, [windowRef, windowAppId, dockIconLocationState, windowPosition, windowSize])

    const variants = {
        visible: { opacity: 1, scale: 1 },
        hidden: hiddenVariant
    };

    return (
        <AnimatePresence>
            <motion.div className="position-absolute flex-column rounded-2"
                style={{
                    left: windowPosition.x,
                    top: windowPosition.y,
                    width: windowSize.x,
                    height: windowSize.y,
                    display: 'flex'
                }}
                ref={windowRef}
                onMouseDown={handleClickOnWindowToFocus}
                id='macwindow'
                variants={variants}
                initial="visible"
                animate={isWindowSuspended ? "hidden" : "visible"}
                exit="exit"
                transition={{ duration: 0.5 }}
            >
                <div className="window-top d-flex bg-dark-transparent rounded-top-2 ps-2" ref={windowTopRef} onMouseDown={onMouseDown} id='macwindow-topbar'>
                    <div className="circular-button close m-2" onClick={handleCloseButtonClicked}></div>
                    <div className="circular-button minimize m-2 d-none d-md-block" onClick={handleMinimizeButtonClicked}></div>
                    <div className="circular-button fullscreen m-2 d-none d-md-block"></div>
                </div>
                <div className="window-body text-light rounded-bottom-2 flex-grow-1 overflow-auto" >
                    {windowContentId ?
                        contentMap[windowContentId]() : windowIFrameContent ?
                            <IFrameWindow source={windowIFrameContent.source} title={windowIFrameContent.title} isDragging={isDragging} /> : <div> some </div>}
                </div>

            </motion.div>
        </AnimatePresence>

    )
}

export default MacWindow