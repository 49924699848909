import MacWindowModel from 'models/MacWindowModel'
import AboutMe from 'components/AboutMe/AboutMe'
import AboutThisPage from 'components/AboutThisPage/AboutThisPage'
import AudioPlayer from 'components/AudioPlayer/AudioPlayer'
import resume from 'assets/resume.pdf'
import APP_CONSTANT from 'constants/AppConstant'


const macWindowContentData: MacWindowModel[] = [
    {
        windowId: APP_CONSTANT.ABOUT_ME_ID, 
        windowContentId: "about-me", 
        windowAppId: APP_CONSTANT.ABOUT_ID,
        isWindowSuspended: false,
        windowIFrameContent: null,
        windowRenderSize: {width: 600, height: 600}, 
        windowRenderCoord: {x: 0, y: 0}
    },
    {
        windowId: APP_CONSTANT.ABOUT_THIS_PAGE_ID,
        windowContentId: "about-this-page",
        windowAppId: APP_CONSTANT.ABOUT_ID,
        isWindowSuspended: false,
        windowIFrameContent: null,
        windowRenderSize: {width: 600, height: 500},
        windowRenderCoord: {x: 0, y: 0}
    },
    {
        windowId: APP_CONSTANT.VIEW_MY_RESUME_ID,
        windowContentId: "",
        windowAppId: APP_CONSTANT.RESUME_ID,
        isWindowSuspended: false,
        windowIFrameContent: {source: resume, title: "Toan Pham's resume"},
        windowRenderSize: {width: 900, height: 1200},
        windowRenderCoord: {x: 0, y: 0},
    },
    {
        windowId: APP_CONSTANT.TYPO_GG_ID,
        windowContentId: "",
        windowAppId: APP_CONSTANT.PROJECT_ID,
        isWindowSuspended: false,
        windowIFrameContent: {source: "https://noatp.github.io/typogg/", title: "typo.gg"}, 
        windowRenderSize: {width: 1200, height: 900},
        windowRenderCoord: {x: 0, y: 0},
    },
    {
        windowId: APP_CONSTANT.UNDERTALE_PIANO_ID,
        windowContentId: "undertale-piano",
        windowAppId: APP_CONSTANT.MUSIC_ID,
        isWindowSuspended: false,
        windowIFrameContent: null,
        windowRenderSize: {width: 400, height: 200},
        windowRenderCoord: {x: 0, y: 0},
    },
]

export const contentMap: Record<string, () => React.ReactElement> = {
    "about-me": () => <AboutMe />,
    "about-this-page": () => <AboutThisPage />,
    "undertale-piano": () => <AudioPlayer />
}

export default macWindowContentData 
