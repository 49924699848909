import { useCallback, useEffect, useMemo, useState } from "react";
import macWindowData from "data/macWindowData";
import APP_CONSTANT from "constants/AppConstant";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { addWindow, bringWindowToFront, restoreWindow } from "slices/windowListSlice";
import { removeSavedWindowPosition } from "slices/appListSlice";
import MacAppModel from "models/MacAppModel";
import { renderCoordsModel } from "models/SubMenuModel";
import { motion, AnimatePresence } from 'framer-motion';

interface SubMenuStyle {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
    width: string;
}

function calculateSubMenuStyle(renderCoords: renderCoordsModel, subMenuWidth: number, innerWidth: number): SubMenuStyle {
    const isOverDock = renderCoords.bottom !== 0;
    const isExtendingRight = renderCoords.left + subMenuWidth < innerWidth;

    const commonStyle = {
        width: `${subMenuWidth}px`
    };

    if (isOverDock) {
        return {
            ...commonStyle,
            bottom: `${renderCoords.bottom}px`,
            [isExtendingRight ? 'left' : 'right']: isExtendingRight
                ? `${renderCoords.left - APP_CONSTANT.CARET_SELF_OFFSET}px`
                : `${innerWidth - renderCoords.right - APP_CONSTANT.CARET_SELF_OFFSET}px`
        };
    } else {
        return {
            ...commonStyle,
            top: `${renderCoords.top}px`,
            [isExtendingRight ? 'left' : 'right']: isExtendingRight
                ? `${renderCoords.left}px`
                : `${innerWidth - renderCoords.right}px`
        };
    }
}

function findAppById(appList: MacAppModel[], appId: string): MacAppModel | undefined {
    return appList.find(app => app.appId === appId);
}

function SubMenu(): React.ReactElement {
    const dispatch = useDispatch();
    const windowList = useSelector((state: RootState) => state.windowList);
    const subMenu = useSelector((state: RootState) => state.subMenu);
    const appList = useSelector((state: RootState) => state.appList);
    const { appId, renderCoords } = subMenu;

    const [subMenuStyle, setSubMenuStyle] = useState<SubMenuStyle>({
        top: `${renderCoords.top}px`,
        left: `${renderCoords.left}px`,
        width: `0px`
    });

    const [isExtendingRight, setIsExtendingRight] = useState<boolean>(true);
    const [shouldShowCaret, setShouldShowCaret] = useState<boolean>(false);
    const subMenuWidth = 200;

    const variants = {
        initial: { opacity: 0, scale: 0.9 }, 
        animate: { opacity: 1, scale: 1 },   
        exit: { opacity: 0, scale: 0.9 }     
    };

    const handleSubMenuOptionClick = useCallback((windowIdToLookUp: string) => {
        const windowExist = windowList.some(window => window.windowId === windowIdToLookUp);

        const suspendedWindow = findAppById(appList, appId)?.suspendedWindowList.find(window => window.windowId === windowIdToLookUp);
        if (suspendedWindow) {
            dispatch(removeSavedWindowPosition([appId, windowIdToLookUp]));
            dispatch(restoreWindow(windowIdToLookUp));
            return;
        }

        if (windowExist) {
            dispatch(bringWindowToFront(windowIdToLookUp));
            return;
        }
        
        const newWindow = macWindowData.find(window => window.windowId === windowIdToLookUp);
        if (newWindow) {
            dispatch(addWindow(newWindow));
        }
    }, [windowList, appList, appId, dispatch]);

    useEffect(() => {
        const updateSubMenuDirection = () => {
            const innerWidth = window.innerWidth;
            const newStyle = calculateSubMenuStyle(renderCoords, subMenuWidth, innerWidth);
            setSubMenuStyle(newStyle);
            setIsExtendingRight(newStyle.hasOwnProperty('left'));
            setShouldShowCaret(renderCoords.bottom !== 0);
        };

        window.addEventListener('resize', updateSubMenuDirection);
        updateSubMenuDirection();

        return () => {
            window.removeEventListener('resize', updateSubMenuDirection);
        };
    }, [renderCoords]);

    const subMenuOptions = useMemo(() => {
        const appOfSubMenu = findAppById(appList, appId);
        if (!appOfSubMenu) return [];

        return appOfSubMenu.appOptionList.map((option) => (
            <div
                key={option.windowId}
                className="submenu-option rounded-2 m-1 ps-2 pe-2"
                onMouseDown={() => handleSubMenuOptionClick(option.windowId)}
                id={`${APP_CONSTANT.SUBMENU_OPTION_PREFIX}${option.windowId}`}
            >
                <div>{option.windowTitle}</div>
                {appOfSubMenu.suspendedWindowList.some(window => window.windowId === option.windowId) && <div className="dot"></div>}
            </div>
        ));
    }, [appList, appId, handleSubMenuOptionClick]);

    const offsetValue = `${renderCoords.caretOffset - APP_CONSTANT.CARET_WIDTH / 2 + APP_CONSTANT.CARET_SELF_OFFSET}px`;
    const caretStyle = isExtendingRight ? { left: offsetValue } : { right: offsetValue };

    return (
        <AnimatePresence>
            {subMenu.options.length > 0 && <motion.div
                key="submenu"
                className="submenu position-absolute bg-dark-transparent text-light rounded-2 shadow-border"
                style={subMenuStyle}
                id="submenu"
                variants={variants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.25}}
            >
                {subMenuOptions}
                {shouldShowCaret && <div className="caret" style={caretStyle}></div>}
            </motion.div>}
        </AnimatePresence>
    );
}

export default SubMenu