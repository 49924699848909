import undertale from "assets/handmade.mp3"

function AudioPlayer(): React.ReactElement {
    return (
        <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <audio id="myAudio" controls>
                <source src={undertale} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </div>
        
    )
}

export default AudioPlayer