import { useEffect, useRef } from 'react'
import MenuBar from "components/MenuBar/MenuBar"
import SubMenu from 'components/SubMenu/SubMenu';
import MacWindow from 'components/MacWindow/MacWindow';
import background from 'assets/background.jpg';
import Dock from 'components/Dock/Dock';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useDispatch } from 'react-redux';
import { hideSubMenu } from 'slices/subMenuSlice';
import { unholdDockIcon } from 'slices/dockIconSlice';
import './App.css';

function App(): React.ReactElement {
  const appRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const windowListState = useSelector((state: RootState) => state.windowList)

  useEffect(() => {
    const adjustHeight = () => {
      if (appRef.current) {
        appRef.current.style.height = window.innerHeight + 'px';
      }
    };

    window.addEventListener('resize', adjustHeight);
    adjustHeight();

    return () => window.removeEventListener('resize', adjustHeight);
  }, []);

  function handleClickOnBackground(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const target = event.target as HTMLElement
    if (!(target.closest('.menu-btn') || target.closest(".dock-icon-container"))) {
      dispatch(hideSubMenu());
      dispatch(unholdDockIcon());
    }
  }

  const windows = windowListState.map((window) => (
    <MacWindow
      key={window.windowId}
      window={window}
    />
  ))

  return (
    <div id='app' className="app position-relative user-select-none" onMouseDown={handleClickOnBackground} ref={appRef}>
      <MenuBar />
      <img className="app-background position-fixed w-100 h-100"
        src={background}
        alt="background"
        draggable="false"
      />
      <Dock />
      <SubMenu />
      {windows}
      
    </div>
  );
}

export default App;
