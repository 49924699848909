import { useState, useEffect } from 'react';
import pawLogo from 'assets/paw.png';

function MenuBar() {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); 

        return () => {
            clearInterval(timer); 
        };
    }, []);

    const dateFormatOptions: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', dateFormatOptions).format(currentTime);
    const formattedDateWithoutCommas = formattedDate.replace(/,/g, '');

    return (
        <div className="menu-bar d-flex align-items-center bg-light" id="menubar">
            <img src={pawLogo} className='paw-logo ms-3 me-3' alt="Paw Logo" draggable="false" />
            <div className="menu-btn ms-auto me-3">{formattedDateWithoutCommas}</div>
        </div>
    );
}

export default MenuBar;
