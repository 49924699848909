import './Dock.css';
import DockIcon from 'components/DockIcon/DockIcon';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

function Dock(): React.ReactElement {
    const dockRef = useRef<HTMLDivElement>(null)
    const isDockIconHeld = useSelector((state: RootState) => state.dockIcon);
    const appListState = useSelector((state: RootState) => state.appList)
    const dockIcons = appListState.map(macApp => {
        return (
            <DockIcon
                key={macApp.appId}
                macApp={macApp}
                dockRef={dockRef}
            />
        )
    })

    return (
        <div
            className="dock position-absolute d-flex align-items-end justify-content-around bg-dark-transparent bottom-0 start-50 translate-middle rounded-4 p-2 inverse-shadow-border"
            id='dock'
            ref={dockRef}
        >
            {isDockIconHeld && <div className="position-absolute w-100 h-100" style={{ backgroundColor: 'transparent', zIndex: 10 }}></div>}

            {dockIcons}
        </div>
    )
}

export default Dock