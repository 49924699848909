import { useCallback, useEffect, useRef, useState } from "react";
import MacAppModel from "models/MacAppModel";
import APP_CONSTANT from "constants/AppConstant";
import { useDispatch, useSelector } from "react-redux";
import { showSubMenu } from "slices/subMenuSlice";
import { RootState } from "store";
import { holdDockIcon } from "slices/dockIconSlice";
import { updateDockIconLocation } from "slices/dockSlice";

const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;

interface DockIconProps {
    macApp: MacAppModel;
    dockRef: React.RefObject<HTMLDivElement>;
}

function DockIcon(props: DockIconProps): React.ReactElement {
    const { macApp, dockRef } = props;

    const [lastKnownMousePosition, setLastKnownMousePosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 })
    const [iconWidth, setIconWidth] = useState(30); // Initial width set to 30px
    const iconContainerRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();
    const isDockIconHeld = useSelector((state: RootState) => state.dockIcon);

    const calculateWidthBasedOnDistance = (distance: number) => {
        const maxDistance = 120; // Maximum distance at which to affect width
        const minWidth = 30;
        const maxWidth = 70;
        if (distance > maxDistance) return minWidth;
        return maxWidth - (distance / maxDistance) * (maxWidth - minWidth);
    };

    const resizeDockIcon = useCallback((event: MouseEvent) => {
        if (iconContainerRef.current && dockRef.current) {
            const dockRect = dockRef.current.getBoundingClientRect();
            const iconContainerRect = iconContainerRef.current.getBoundingClientRect();
            if (event.clientY > dockRect.bottom || event.clientY < dockRect.top || event.clientX < dockRect.left || event.clientX > dockRect.right) {
                setIconWidth(30); // Reset to minimum width
                return;
            }
            const centerX = iconContainerRect.left + iconContainerRect.width / 2;
            const distance = Math.abs(centerX - event.clientX);
            const newWidth = calculateWidthBasedOnDistance(distance);
            setIconWidth(newWidth);
        }
    }, [dockRef]);

    //resume icon animation after held
    useEffect(() => {
        if (isTouchDevice) return;

        if (!isDockIconHeld) {
            const event = new MouseEvent("mousemove", {
                clientX: lastKnownMousePosition.x,
                clientY: lastKnownMousePosition.y
            });
            resizeDockIcon(event);
        }
    }, [isDockIconHeld, lastKnownMousePosition, resizeDockIcon]);

    //animate icon
    useEffect(() => {
        if (isTouchDevice) return;

        let animationFrameId: number;
        const handleMouseMove = (event: MouseEvent) => {
            setLastKnownMousePosition({ x: event.clientX, y: event.clientY })
            cancelAnimationFrame(animationFrameId);
            animationFrameId = requestAnimationFrame(() => {
                if (!isDockIconHeld) {
                    resizeDockIcon(event)
                }
            });
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            cancelAnimationFrame(animationFrameId);
        };
    }, [isDockIconHeld, resizeDockIcon]);

    const updateIconLocationState = useCallback(() => {
        if (iconContainerRef.current) {
            const iconContainerRect = iconContainerRef.current.getBoundingClientRect();
            dispatch(updateDockIconLocation({
                appId: macApp.appId,
                x: iconContainerRect.left + iconContainerRect.width / 2,
                y: iconContainerRect.top
            }))
        }
    }, [dispatch, macApp]) 

    useEffect(() => {
        window.addEventListener('resize', updateIconLocationState);

        updateIconLocationState();

        return () => {
            window.removeEventListener('resize', updateIconLocationState);
        };
    }, [updateIconLocationState])

    function handleClickOnDockIcon(event: React.MouseEvent) {
        dispatch(holdDockIcon());
        if (dockRef && dockRef.current) {
            const targetElement = event.target as HTMLDivElement;
            const dockRect = dockRef.current.getBoundingClientRect();
            const iconRect = targetElement.getBoundingClientRect();
            const options = macApp.appOptionList;
            dispatch(showSubMenu({
                appId: macApp.appId,
                options: options,
                renderCoords: {
                    top: 0,
                    left: iconRect.left,
                    right: iconRect.right,
                    bottom: window.innerHeight - dockRect.top + (isTouchDevice
                        ? APP_CONSTANT.CARET_HEIGHT
                        : APP_CONSTANT.SUBMENU_Y_OFFSET_ABOVE_DOCK + APP_CONSTANT.CARET_HEIGHT),
                    caretOffset: iconRect.width / 2
                }
            }))
        }
    }

    return (
        <div className="d-flex flex-column align-items-center">
            <div
                className="dock-icon-container bg-soft-white p-2 rounded-4 inverse-shadow-border"
                ref={iconContainerRef}
                onMouseDown={handleClickOnDockIcon}
            >
                <img
                    className="dock-icon"
                    style={{ width: `${iconWidth}px` }}
                    src={macApp.appIcon}
                    alt={`${macApp.appTitle} icon`}
                    id={`dock-icon-${macApp.appId}`}
                    draggable="false"
                />
            </div>
            <div className="text-light">{macApp.appTitle}</div>
        </div>
    );
}

export default DockIcon;
