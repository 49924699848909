// store.ts
import { configureStore } from '@reduxjs/toolkit';
import windowListReducer from "./slices/windowListSlice"
import subMenuReducer from "./slices/subMenuSlice"
import dockIconReducer from './slices/dockIconSlice';
import appListReducer from './slices/appListSlice';
import dockReducer from './slices/dockSlice';

export const store = configureStore({
  reducer: {
    windowList: windowListReducer,
    subMenu: subMenuReducer,
    dockIcon: dockIconReducer,
    appList: appListReducer,
    dockIconList: dockReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
