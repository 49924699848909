const APP_CONSTANT = {
    ABOUT_ID: "about",
    RESUME_ID: "resume",
    PROJECT_ID: "project",
    MUSIC_ID: "music",

    ABOUT_TITLE: "About",
    RESUME_TITLE: "Resume",
    PROJECT_TITLE: "Project",
    MUSIC_TITLE: "Music",

    ABOUT_ME_ID: "about-me",
    ABOUT_THIS_PAGE_ID: "about-this-page",
    VIEW_MY_RESUME_ID: "view-my-resume",
    TYPO_GG_ID: "typo-gg",
    UNDERTALE_PIANO_ID: "undertale-piano",

    ABOUT_ME_TITLE: "About me",
    ABOUT_THIS_PAGE_TITLE: "About this page",
    VIEW_MY_RESUME_TITLE: "View my resume",
    TYPO_GG_TITLE: "Typo.gg",
    UNDERTALE_PIANO_TITLE: "Undertale piano",

    MENUBAR_HEIGHT: 26,

    SUBMENU_OPTION_PREFIX: "submenu-option-",
    SUBMENU_Y_OFFSET_ABOVE_DOCK: 35,

    CARET_SELF_OFFSET: 20,
    CARET_WIDTH: 20,
    CARET_HEIGHT: 10
}

export default APP_CONSTANT