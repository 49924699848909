import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: { appId: string; x: number; y: number }[] = [];

export const dockSlice = createSlice({
    name: 'dock',
    initialState,
    reducers: {
        updateDockIconLocation: (state, action: PayloadAction<{ appId: string; x: number; y: number }>) => {
            const index = state.findIndex(dockIcon => dockIcon.appId === action.payload.appId);
            if (index !== -1) {
                state[index].x = action.payload.x;
                state[index].y = action.payload.y;
            }
            else {
                state.push(action.payload)
            }
        }
    },
});

export const { updateDockIconLocation } = dockSlice.actions;
export default dockSlice.reducer;
