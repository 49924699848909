export interface IFrameWindowProps {
    source: any,
    title: string,
    isDragging: boolean
}

function IFrameWindow(props: IFrameWindowProps): React.ReactElement {
    const {source, title, isDragging} = props
    return (
        <iframe 
            src={source} 
            className={`${isDragging ? 'pe-none' : 'pe-auto'}`} 
            style={{ width: '100%', height: '99%' }} 
            title={title}
        ></iframe>
    )
}

export default IFrameWindow