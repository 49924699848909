function AboutThisPage(): React.ReactElement {
    return (
        <div className="p-3">
            This page serves as my playground for ReactJS and TypeScript. 
            My tech stack is primarily React and Bootstrap. 
            I started learning React and TypeScript out of curiosity and to broaden my career horizons. <br />
            <br />
            At the time of writing this, I've found React to be both straightforward and challenging. 
            Its similarity to Jetpack Compose, particularly in state management and reactive aspects, makes it approachable. 
            However, I still don't fully grasp its asynchronous rendering nature and the components lifecycle. 
            I'm also new to TypeScript and React best practices, so you might encounter some spaghetti code somewhere in here.
            I'm learning and improving along the way. <br />
            <br />
            The design of this page is inspired by the MacOS homescreen,
            with a menu bar at the top and a close button on the left. 
            It's not without its quirks and bugs, though, so if you spot any, please feel free to reach out and let me know. <br />
            <br />
            Again, thank you for visiting!
        </div>
    )
}

export default AboutThisPage