import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import MacAppModel from 'models/MacAppModel';
import macAppData from 'data/macAppData';
import MacWindowModel from 'models/MacWindowModel';

const initialState: MacAppModel[] = macAppData;

export const appListSlice = createSlice({
  name: 'appList',
  initialState: initialState,
  reducers: {
    saveWindowPosition: (state, action: PayloadAction<MacWindowModel>) => {
        const appToUpdate = state.find(app => app.appId === action.payload.windowAppId)

        if (appToUpdate) {
            appToUpdate.suspendedWindowList.push(action.payload)
        }
    },
    removeSavedWindowPosition: (state, action: PayloadAction<[appId: string, windowId: string]>) => {
      const [appId, windowId] = action.payload;

      const appToUpdate = state.find(app => app.appId === appId);
      if (appToUpdate) {
          appToUpdate.suspendedWindowList = appToUpdate.suspendedWindowList.filter(window => window.windowId !== windowId);
      }
    }
  },
});

export const { saveWindowPosition, removeSavedWindowPosition } = appListSlice.actions;
export default appListSlice.reducer;