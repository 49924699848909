import APP_CONSTANT from "constants/AppConstant";
import MacAppModel from "models/MacAppModel";
import aboutIcon from 'assets/icons8-about.svg'
import resumeIcon from 'assets/icons8-briefcase.svg'
import projectIcon from 'assets/icons8-puzzle.svg'
import musicIcon from 'assets/icons8-music.svg'

const aboutMeOption = { windowId: APP_CONSTANT.ABOUT_ME_ID, windowTitle: APP_CONSTANT.ABOUT_ME_TITLE }
const aboutThisPageOption = { windowId: APP_CONSTANT.ABOUT_THIS_PAGE_ID, windowTitle: APP_CONSTANT.ABOUT_THIS_PAGE_TITLE }
const viewMyResumeOption = { windowId: APP_CONSTANT.VIEW_MY_RESUME_ID, windowTitle: APP_CONSTANT.VIEW_MY_RESUME_TITLE }
const typoGGOption = { windowId: APP_CONSTANT.TYPO_GG_ID, windowTitle: APP_CONSTANT.TYPO_GG_TITLE }
const undertalePianoOption = { windowId: APP_CONSTANT.UNDERTALE_PIANO_ID, windowTitle: APP_CONSTANT.UNDERTALE_PIANO_TITLE }


const macAppData: MacAppModel[] = [
    {
        appId: APP_CONSTANT.ABOUT_ID,
        appTitle: APP_CONSTANT.ABOUT_TITLE,
        appIcon: aboutIcon,
        appOptionList: [aboutMeOption, aboutThisPageOption],
        suspendedWindowList: []
    },
    {
        appId: APP_CONSTANT.RESUME_ID,
        appTitle: APP_CONSTANT.RESUME_TITLE,
        appIcon: resumeIcon,
        appOptionList: [viewMyResumeOption],
        suspendedWindowList: []
    },
    {
        appId: APP_CONSTANT.PROJECT_ID,
        appTitle: APP_CONSTANT.PROJECT_TITLE,
        appIcon: projectIcon,
        appOptionList: [typoGGOption],
        suspendedWindowList: []
    },
    {
        appId: APP_CONSTANT.MUSIC_ID,
        appTitle: APP_CONSTANT.MUSIC_TITLE,
        appIcon: musicIcon,
        appOptionList: [undertalePianoOption],
        suspendedWindowList: []
    }

]

export default macAppData