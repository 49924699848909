import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import MacWindowModel from 'models/MacWindowModel';

const initialState: MacWindowModel[] = [];

export const windowListSlice = createSlice({
  name: 'windowList',
  initialState: initialState,
  reducers: {
    addWindow: (state, action: PayloadAction<MacWindowModel>) => {
      state.push(action.payload);
    },
    removeWindow: (state, action: PayloadAction<string>) => {
      const index = state.findIndex(window => window.windowId === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    bringWindowToFront: (state, action: PayloadAction<string>) => {
      const index = state.findIndex(window => window.windowId === action.payload);
      if (index !== -1) {
        const [windowToMove] = state.splice(index, 1);
        state.push(windowToMove);
      }
    },
    minimizeWindow: (state, action: PayloadAction<string>) => {
      const index = state.findIndex(window => window.windowId === action.payload);
      if (index !== -1) {
        state[index].isWindowSuspended = true
      }
    },
    restoreWindow: (state, action: PayloadAction<string>) => {
      const index = state.findIndex(window => window.windowId === action.payload);
      if (index !== -1) {
        state[index].isWindowSuspended = false
      }
    }
  },
});

export const { addWindow, removeWindow, bringWindowToFront, minimizeWindow, restoreWindow } = windowListSlice.actions;
export default windowListSlice.reducer;
