import { createSlice } from '@reduxjs/toolkit';

export const dockIconSlice = createSlice({
    name: 'dockIcon',
    initialState: false,
    reducers: {
        holdDockIcon: (state) => {
            return true;
        },
        unholdDockIcon: (state) => {
            return false;
        }
    },
});

export const { holdDockIcon, unholdDockIcon } = dockIconSlice.actions;
export default dockIconSlice.reducer;
