import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SubMenuModel from 'models/SubMenuModel';

const initialState: SubMenuModel = {
    appId: "",
    options: [],
    renderCoords: {
        top: 0,
        left: 0, 
        right: 0,
        bottom: 0,
        caretOffset: 0
    }
};

export const subMenuSlice = createSlice({
  name: 'subMenu',
  initialState: initialState,
  reducers: {
    showSubMenu: (state, action: PayloadAction<SubMenuModel>) => {
        state.appId = action.payload.appId;
        state.options = action.payload.options;
        state.renderCoords = action.payload.renderCoords;
    },
    hideSubMenu: (state) => {
        state.options = [];
    }
  },
});

export const { showSubMenu, hideSubMenu } = subMenuSlice.actions;
export default subMenuSlice.reducer;