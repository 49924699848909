import profile from 'assets/profile.jpg'

function AboutMe(): React.ReactElement {
    return (
        <div className="p-3" id='macwindow-aboutme'>
            <img src={profile} alt="Profile" className='profile-pic float-start float-md-none pe-2 pb-2' draggable='false'/>
            <div className=''>
                Hello there! I'm Toan Pham. Welcome to my website! <br />
                <br />
                I'm a full-time Software Engineer at Citi, based in the DFW metroplex area. 
                My primary focus is on iOS and microservices development. 
                I really enjoy iOS development, as well as Apple's ability to streamline and simplify the development process. 
                I am excited about SwiftUI and other reactive, declarative frameworks. <br />
                <br />
                Beyond programming, my interests lie in technology and engineering. 
                I enjoy hands-on projects, whether it's building PCs, building custom keyboards, DIY a digital photo frame, 
                or just fixing things
                and creating quality-of-life improvements around my home. <br />
                <br />
                Music is another passion of mine. I can play a few instruments, including the piano, guitar, and cajon box drum. 
                I also made a few tracks. Feel free to check out some bit and pieces the music section. <br />
                <br />
                For more details about my professional experience, please take a look at my resume. <br />
                <br />
                Thank you for visiting, and enjoy exploring my site!
            </div>
        </div>
    )
}

export default AboutMe